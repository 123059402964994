import React from 'react';
import { graphql } from 'gatsby';
import styles from './styles.module.css';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';

const picture = require('./assets/picture.png');
const simple = require('./assets/Discounting_info_body_simple_icon.png');
const price = require('./assets/Discounting_info_body_price_icon.png');
const nocheck = require('./assets/Discounting_info_body__nocheck_icon.png');

export default function DiscountingPage(props: any) {
  return (
    <div>
      <div className={styles.pageTop}>
        <img src={picture} className={styles.pageTopImage} />
        <Container>
          <Row className='justify-content-end'>
            <Col className={styles.pageTopCol} md={6} sm={8}>
              <h1 className={styles.pageTopTitle}>{t(props.labels, 'Discounting_info_top_text', 'heb')}</h1>
              <p className={styles.pageTopText}>{t(props.labels, 'Discounting_info_top_body', 'heb')}</p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className={styles.advanceBodyContainer}>
        <Row>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Discounting_info_value_work_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Discounting_info_value_work_down', 'heb')}</div>
          </Col>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Discounting_info_value_timewire_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Discounting_info_value_timewire_down', 'heb')}</div>
          </Col>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Discounting_info_value_interest_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Discounting_info_value_interest_down', 'heb')}</div>
          </Col>
          <Col sm={3} className={styles.info_col}>
            <div className={styles.info_up}>{t(props.labels, 'Discounting_info_value_upto_up', 'heb')}</div>
            <div className={styles.info_down}>{t(props.labels, 'Discounting_info_value_upto_down', 'heb')}</div>
          </Col>
        </Row>
        <Row className={styles.bodyTitle}>
          <Col md={12}>{t(props.labels, 'Discounting_info_body_title', 'heb')}</Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row className='mb-5'>
              <Col md={8}>
                <b className={styles.advanceBodyTitle}>
                  {t(props.labels, 'Discounting_info_body_simple_title', 'heb')}
                </b>
                <p className={styles.advanceBodyText}>{t(props.labels, 'Discounting_info_body_simple_body', 'heb')}</p>
              </Col>
              <Col md={4}>
                <img className={styles.fundingProcessImage} src={simple} />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className='mb-5'>
              <Col md={8}>
                <b className={styles.advanceBodyTitle}>
                  {t(props.labels, 'Discounting_info_body_nocheck_title', 'heb')}
                </b>
                <p className={styles.advanceBodyText}>{t(props.labels, 'Discounting_info_body_nocheck_body', 'heb')}</p>
              </Col>
              <Col md={4}>
                <img className={styles.fundingProcessImage} src={nocheck} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6}></Col>
          <Col md={6}>
            <Row className='mb-5'>
              <Col md={8}>
                <b className={styles.advanceBodyTitle}>{t(props.labels, 'Discounting_info_body_price_title', 'heb')}</b>
                <p className={styles.advanceBodyText}>{t(props.labels, 'Discounting_info_body_price_body', 'heb')}</p>
              </Col>
              <Col md={4}>
                <img className={styles.fundingProcessImage} src={price} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className={styles.bottomImageDiv}></div>
    </div>
  );
}
